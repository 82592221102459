import * as React from "react";
import Comments from "../../../components/Comments";

type PostFooterProps = {
  post: {
    slug: string;
    title: string;
    date: string;
    tags?: {
      name: string;
      slug: string;
    }[];
    description?: string;
    canonicalUrl?: string;
    body: string;
    excerpt: string;
    timeToRead?: number;
    banner?: {
      childImageSharp: {
        resize: {
          src: string;
        };
      };
    };
  };
};

const PostFooter = ({ post }: PostFooterProps) => <Comments />;

export default PostFooter;
