import React, { useEffect, useRef } from "react";
// import { useColorMode } from "theme-ui";

const Comments = () => {
  const commentBoxRef = useRef<HTMLDivElement>();

  useEffect(() => {
    const scriptEl = document.createElement("script");
    scriptEl.setAttribute("src", "https://utteranc.es/client.js");
    scriptEl.setAttribute("crossorigin", "anonymous");
    scriptEl.setAttribute("async", "true");
    scriptEl.setAttribute("repo", "ma-pe/ptrs.dev.comments");
    scriptEl.setAttribute("issue-term", "pathname");
    scriptEl.setAttribute("theme", "github-light");
    commentBoxRef.current.innerHTML = "";
    commentBoxRef.current.appendChild(scriptEl);
  }, []);

  return <div key="1" ref={commentBoxRef} />;
};

export default Comments;
